import React, { useCallback } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useDispatch } from "react-redux";
import { Button } from '@mui/material';
import { actions } from 'pages/PatientView/PatientView.redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';



const PatientChartsCell = (props) => {
	const id             = props.row.visit_id,
		  dispatch       = useDispatch(),
		  { patient_id } = useParams();

	const { column, row } = props;

	const handleCopyChart = useCallback(() => {
		dispatch(actions.addNewChart(patient_id, row.visit_id));
	}, [ dispatch, patient_id, row.visit_id ]);

	const handleNavigate = useCallback(() => {
		dispatch(push(`/chart/${id}`));
	}, [ dispatch, id ]);


	if(column.name === 'cloneChart') {
		return (
			<Table.Cell
				{...props}
			>
				<Button variant="outlined" size="small" onClick={handleCopyChart}>
					Copy Chart
				</Button>
			</Table.Cell>
		);
	}

	return (
		<Table.Cell
			{...props}
			onClick={handleNavigate}
		/>
	);
};


export default PatientChartsCell;
