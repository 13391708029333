import React, { useCallback } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import { useDispatch } from "react-redux";



const PatientChartsTableRow = ({ children, ...props }) => {
	const id       = props.row.visit_id,
		  dispatch = useDispatch();


	return (
		<Table.Row
			{...props}
			style={{
				cursor: 'pointer',
			}}
		>
			{children}
		</Table.Row>
	);
};


export default PatientChartsTableRow;
