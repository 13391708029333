import React, { useRef, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './Reports.redux';
import { push } from 'connected-react-router';
import api from 'api';
import saveAs from 'file-saver';
import { actions as fileActions } from 'components/FileManager/FileManager.redux';
import FileManager from 'components/FileManager';
import useStyles from './WysiwygEditor.styles';
import { Button } from '@mui/material';



const defaultFileManagerProps = {
	client_id: null,
	patient_id: null,
	visit_id: null,
	urpt_id: null,
	defaultPage: 'upload',
};

const WysiwygEditor = ({
	value = '',
	onChange,
	templates = [],
	showReplacementButton = false,
	navItems = [],
	autofill = [],
	fileManagerProps = { ...defaultFileManagerProps },
}) => {
	// Start component
	const editorRef                     = useRef(null),
		  dispatch                      = useDispatch(),
		  reportName                    = useSelector(state => state.reports.report?.urpt_name),
		  [ mceEditor, setMceEditor ]   = useState(null),
		  [ loadingPdf, setLoadingPdf ] = useState(false),
		  classes                       = useStyles();

	const log = () => {
		if(editorRef.current) {
			console.log(editorRef.current.getContent());
		}
	};


	const getPDF = async () => {
		setLoadingPdf(true);

		try {
			let pdf = await api.post('/pdf', {
				content: value,
			}, {
				timeout: 15000,
			});

			let binary = atob(pdf.data.replace(/\s/g, '')),
				len    = binary.length,
				buffer = new ArrayBuffer(len),
				view   = new Uint8Array(buffer);

			for(let i = 0; i < len; i++) {
				view[ i ] = binary.charCodeAt(i);
			}

			let blob     = new Blob([ view ], { type: "application/pdf" }),
				d        = new Date(),
				fileName = `Sabertooth Report - ${reportName} - ${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}.pdf`;

			saveAs(blob, fileName);
		} catch(err) {
			console.error(err);
		}

		setLoadingPdf(false);
	};


	const handleOpenFileManager = () => {
		dispatch(fileActions.setState({ open: true }));
	};



	return (
		<div className={classes.editor}>
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + '/lib/tinymce/tinymce.min.js'}
				value={value}
				onEditorChange={onChange}
				onInit={(event, editor) => {
					editorRef.current = editor;

					let onAction = function(autocompleteApi, range, value) {
						editor.selection.setRng(range);
						editor.insertContent(value);
						autocompleteApi.hide();
					};

					let getMatchedChars = function(pattern) {
						return autofill.filter(function(char) {
							return char.text.toLowerCase().startsWith(pattern);
						});
					};

					// Start autocomplete
					editor.ui.registry.addAutocompleter('specialchars_cardmenuitems', {
						ch: '/',
						minChars: 0,
						columns: 1,
						highlightOn: [ 'char_name' ],
						onAction: onAction,
						fetch: function(pattern) {
							return new Promise(function(resolve) {
								let results = getMatchedChars(pattern).map(function(char) {
									return {
										type: 'cardmenuitem',
										value: char.value,
										label: char.text,
										items: [
											{
												type: 'cardcontainer',
												direction: 'vertical',
												items: [
													{
														type: 'cardtext',
														text: char.text,
														name: 'char_name',
													},
												],
											},
										],
									};
								});
								resolve(results);
							});
						},
					});
					// End autocomplete


				}}
				init={{
					height: '100%',
					width: '100%',
					resize: false,
					browser_spellcheck: true,
					menu: {
						navigation: {
							title: 'Navigate',
							items: navItems.map(navItem => navItem.key).join(' '),
						},
					},
					menubar: `edit format table insert ${navItems.length
						? 'navigation'
						: ''}`,
					pagebreak_separator: "<div class=\"page-break\"></div>",
					plugins: [
						'link code table lists hr paste print template pagebreak',
						'advlist autolink lists charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime table paste code help wordcount stFileManager stReplaceTemplates',
					],
					toolbar: 'undo redo | cut copy paste | ' +
						'formatselect fontselect fontsizeselect | ' +
						'bold italic underline forecolor backcolor removeformat | ' +
						'alignleft aligncenter alignright alignjustify | ' +
						'numlist bullist outdent indent pagebreak | ' +
						'table hr stFileManager template stReplaceTemplates | ' +
						'help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
					fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 24pt 36pt",
					templates: templates,
					toolbar_mode: 'wrap',
					setup: (editor) => {
						setMceEditor(editor);

						navItems.forEach(navItem => {
							editor.ui.registry.addMenuItem(navItem.key, {
								text: navItem.label,
								onAction: function() {
									if(navItem.newTab) {
										window.open(navItem.location, '_blank').focus();
									} else {
										dispatch(push(navItem.location));
									}
								},
							});
						});

						editor.ui.registry.addButton('stFileManager', {
							//text: "Image",
							icon: 'image',
							tooltip: 'Upload or Insert Image',
							onAction: handleOpenFileManager,
						});

						if(showReplacementButton === true) {
							editor.ui.registry.addButton('stReplaceTemplates', {
								text: "Update",
								//icon: 'image',
								tooltip: 'Update Template Data',
								onAction: () => dispatch(actions.openTemplateReplacementModal()),
							});
						}
					},
				}}

			/>
			{false && <>
				<button onClick={log}>Log editor content</button>
				<button onClick={getPDF}>Get PDF</button>
			</>}
			<Button
				variant="contained"
				onClick={getPDF}
				sx={{ marginLeft: '80px', marginTop: '8px' }}
				disabled={loadingPdf}
			>
				Download PDF
			</Button>

			<FileManager
				{...defaultFileManagerProps}
				{...fileManagerProps}
				selectActions={[
					{
						name: 'addToReport',
						label: 'Add to report',
						handler: (file) => {
							if(mceEditor) {
								mceEditor.execCommand(
									'mceInsertContent',
									false,
									`<img src=${file.file_url} style="display:block;max-width:100%;width:auto;height:auto;"/>`,
								);
							}
						},
					},
					{
						name: 'addToReportAndClose',
						label: 'Add to report and close',
						handler: (file) => {
							if(mceEditor) {
								mceEditor.execCommand(
									'mceInsertContent',
									false,
									`<img src=${file.file_url} style="display:block;max-width:100%;width:auto;height:auto;"/>`,
								);
								dispatch(fileActions.setState({ open: false }));
							}
						},
					},
				]}
			/>
		</div>
	);
};


export default WysiwygEditor;
